/**
 * Make sure that the given function is run event after `DOMContentLoaded` has already trigger.
 *
 */

export function vtxDomReady(fn) {
  if (typeof fn !== 'function') {
    return;
  }

  if (
    document.readyState === 'interactive' ||
    document.readyState === 'complete'
  ) {
    return fn();
  }

  document.addEventListener('DOMContentLoaded', fn, false);
}

export default vtxDomReady;
