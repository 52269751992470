import { _x } from '@wordpress/i18n';

let fieldsAreInitialized = false;

function changeFileUpload(formID) {
  // select right file uploads
  let singleFileUploads;
  let multipleFileUploads;
  if (formID) {
    const form = document.querySelector('form[data-formid="' + formID + '"');
    singleFileUploads = form.querySelectorAll('.gfield--fileupload-single');
    multipleFileUploads = form.querySelectorAll('.gform_fileupload_multifile');
  } else {
    singleFileUploads = document.querySelectorAll('.gfield--fileupload-single');
    multipleFileUploads = document.querySelectorAll(
      '.gform_fileupload_multifile'
    );
  }
  // single file uploads
  if (0 < singleFileUploads.length) {
    singleFileUploads.forEach(function (fileUpload) {
      const input = fileUpload.querySelector('input[type="file"]');
      const label = fileUpload.querySelector('.gfield_label');
      const noFileLabel = _x('No file selected', 'file upload', 'vtx');
      const filePlaceholder = document.createElement('p');
      filePlaceholder.classList.add('file-placeholder');
      filePlaceholder.textContent = input.value ? input.value : noFileLabel;
      label.after(filePlaceholder);

      input.addEventListener('change', function () {
        filePlaceholder.textContent = input.value ? input.value : noFileLabel;
      });
    });
  }

  // multiple file uploads
  if (0 < multipleFileUploads.length) {
    multipleFileUploads.forEach(function (fileUpload) {
      const instructions = fileUpload.querySelector('.gform_drop_instructions');
      const icon = document.createElement('span');
      icon.classList.add('gform_drop_icon');
      icon.classList.add('icon-ajouter-cv');
      instructions.after(icon);
      instructions.textContent = wp.i18n._x(
        'Drag and drop file to upload',
        'drag and drop instruction',
        'vtx'
      );
    });
  }
}

document.addEventListener('gform_post_render', function (e, formID) {
  fieldsAreInitialized = true;
  changeFileUpload(formID);
});

// ON READY
window.addEventListener('load', function () {
  if (!fieldsAreInitialized) {
    changeFileUpload();
  }
});
